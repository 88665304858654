import { BoxProps, chakra, Flex, Grid, VisuallyHidden } from '@chakra-ui/react';

import { AdminDashboard, useAdminDashboard } from '../admin/admin-dashboard';
import { ServiceStatusNotice } from '../error/service-status-notice';

export type FooterNavProps = {
  sticky?: boolean;
  tabs?: React.ReactNode;
} & BoxProps;

export function FooterNav({
  sticky,
  tabs,
  children,
  ...props
}: FooterNavProps) {
  const { isActive: isAdminActive } = useAdminDashboard();

  const stickyProps = {
    sx: {
      // Only be sticky if there's enough vertical space
      '@media(min-height: 650px)': {
        position: 'sticky',
        bottom: '0',
      },
    },
  } as const;

  return (
    <chakra.footer {...(sticky ? stickyProps : {})} {...props}>
      {tabs}
      <ServiceStatusNotice />
      <Flex
        as="nav"
        aria-labelledby="strive-app-navigation"
        bg="strive.appFooter.bg"
        shadow="md"
        pb={
          isAdminActive ? '8px' : 'max(env(safe-area-inset-bottom, 0) / 2, 8px)'
        }
        pt="2"
      >
        <VisuallyHidden>
          <h2 id="strive-app-navigation">Main</h2>
        </VisuallyHidden>
        <Grid
          templateColumns="repeat(auto-fit, minmax(0, 1fr))"
          gap="2"
          width="full"
        >
          {children}
        </Grid>
      </Flex>
      <AdminDashboard />
    </chakra.footer>
  );
}
