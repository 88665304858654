import { useSelector } from '@xstate/react';
import { createMachine, interpret, InterpreterFrom } from 'xstate';
import { createStore } from 'zustand';

import { authClient, frontend, isOfflineError, queryClient } from '@strive/api';

export const networkStateMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QDkwBcDuB7ATgawAIBlNAQzTADoB5AOwBsBLWsAYmoDMOmWBtABgC6iUAAcssRmkZZaIkAA9EAVgBMAGhABPRAA4AjJQAsATn38TAdgBsy5f0vLdAX2ebUmXIRLkqnbsxsdDxgAsJIIOKS0rLySgjW1paUdrq6ypa6qrr8+gDMypo6CHm6JpTW6ZmZBY551q7u6Nj4xGQUNFwhlACSEPRsCrDtVKQcFDgAFDjoOFoAKowAtmBYAK5oAJSsHi3eI50BLL39oULyUVIychHx+o4p6fyVRnn6RhaORYh5lnmUlj+-GUlTKwKM+kaIF2XjavkO3XmcGktCgrAgsiozAAblg8FQYa0fB1-IjkcwoAgcVgAMbka5hMIXCRXWK3RCJZKpKpZHL5QraH5GVSUVT8D6qEHWMzWIxGKGE-bw0mBShI4YU1hgHA4XCUUT0cgcXBLSiKuEkrqq9UoynUukxWiM84RS6OuKIe7KR72F5vD5WAXFPKqayisyqExGSyqVRGWUmVxuEC0LAQODyc3EsDM6LXD0IAC0Jm+RYayazB2CgVzrJuoHiaW90qc72UJl0eVK1lLeVeFQ7pn5stUIbyCuasOzCJrrpZ7vZJRLgoQ+k7opDDlMst+5aaniJVatxz6A1rC4beksvby-EouiSZTSjmeJmBE4PSstRyoNop5-zRc8hMEVSn0Kw10qAppWvFc3mSSxnksfII3qd8kyAA */
  createMachine(
    {
      tsTypes: {} as import('./network-state.machine.typegen').Typegen0,
      schema: {
        services: {} as {
          testNetworkState: { data: void };
        },
      },
      id: 'Network State',
      initial: 'Online',
      predictableActionArguments: true,

      states: {
        Online: {
          on: {
            Offline: {
              target: 'Offline',
            },
          },
        },
        Offline: {
          exit: 'invalidateQueryCache',
          initial: 'Idle',
          states: {
            Idle: {
              after: {
                retryTimeout: {
                  target: 'Testing',
                },
              },
            },
            Testing: {
              invoke: {
                src: 'testNetworkState',
                onDone: [
                  {
                    target: '#Network State.Online',
                  },
                ],
                onError: [
                  {
                    target: 'Idle',
                  },
                ],
              },
            },
          },
          on: {
            Online: {
              target: 'Online',
            },
          },
        },
      },
    },
    {
      delays: {
        retryTimeout: 15 * 1000, // 15 seconds
      },
      services: {
        testNetworkState: async (ctx, evt) => {
          await frontend.get('/api/ping');
        },
      },
      actions: {
        invalidateQueryCache: (ctx) => {
          queryClient.invalidateQueries();
        },
      },
    },
  );

type NetworkState = {
  service: InterpreterFrom<typeof networkStateMachine>;
};

export const networkStateStore = createStore<NetworkState>((set, get) => {
  // If any network request succeeds, go online
  authClient.interceptors.response.use(
    (response) => {
      getNetworkState().send('Online');
      return response;
    },
    (error) => {
      if (isOfflineError(error)) {
        getNetworkState().send('Offline');
      }
      throw error;
    },
  );

  return {
    service: interpret(networkStateMachine, { devTools: true }).start(),
  };
});

export const getNetworkState = () => networkStateStore.getState().service;

export const useNetworkState = () => {
  return useSelector(getNetworkState(), (state) =>
    state.matches('Offline') ? 'offline' : 'online',
  );
};
