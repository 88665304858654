import { Text } from '@chakra-ui/react';
import { AnimatePresence } from 'framer-motion';

import { MotionBox, MotionBoxProps, TEXT } from '@arena-labs/strive2-ui';

export function FooterNotification({
  id,
  count,
  isNew,
  wrapperProps,
}: {
  id: string;
  count?: number;
  isNew?: boolean;
  wrapperProps?: MotionBoxProps;
}) {
  const animateProps = {
    animate: {
      scale: [0.8, 1, 1.1, 1],
    },
    exit: {
      scale: [0.8, 0.5],
      opacity: 0,
    },
  };
  return (
    <AnimatePresence>
      {isNew ? (
        <MotionBox
          layoutId={id}
          key={`${id}-new`}
          position="absolute"
          color="white"
          top="-15px"
          right="-5px"
          bg="primaryAlpha.30"
          py="0.5"
          px="1"
          borderRadius="sm"
          {...animateProps}
          {...wrapperProps}
        >
          <TEXT.P3 color="secondary.400">NEW</TEXT.P3>
        </MotionBox>
      ) : count ? (
        <MotionBox
          layoutId={id}
          key={`${id}-${count}`}
          display="grid"
          placeItems="center"
          borderRadius="full"
          boxSize="20px"
          position="absolute"
          bg="red.300"
          color="white"
          top="-25px"
          right="20px"
          {...animateProps}
          {...wrapperProps}
        >
          <Text fontWeight="bold" fontSize="xs">
            {count}
          </Text>
        </MotionBox>
      ) : null}
    </AnimatePresence>
  );
}
