import NextLink from 'next/link';
import { Flex, Image, LinkBox, LinkOverlay, Text } from '@chakra-ui/react';
import { Card, CardProps } from 'stream-chat-react';

import { ChatTrackingEvent, useAnalytics } from '@arena-labs/analytics';

export type ChatCardProps = CardProps & {
  image?: React.ReactNode;
};

export function ChatCard({ image, ...props }: ChatCardProps) {
  const analytics = useAnalytics();

  if (props.type === 'image' && props.title_link) {
    // The image attachment has a link we want clicking the image to open the URL, not a zoom in of the image.
    return (
      <LinkBox
        display="flex"
        flexDirection="column"
        gap="3"
        w="full"
        className="sentry-unmask"
      >
        <LinkOverlay
          as={NextLink}
          // A link to a Strive URL should be relative and will be handled by Next.js
          href={props.title_link}
          w="full"
          position="relative"
          onClick={(event) => {
            analytics.logEvent(ChatTrackingEvent.ChatLinkClicked, {
              internal: Boolean(props.title_link?.startsWith('/')),
              linkHref: props.title_link,
              linkTitle: props.title,
              linkText: props.text,
            });
          }}
        >
          {image ?? (
            <Image
              src={props.image_url}
              alt={props.text}
              borderTopRadius="card"
            />
          )}
        </LinkOverlay>
        <Flex gap="1" direction="column" px="2">
          {props.title && <Text fontWeight="bold">{props.title}</Text>}
          {props.text && <Text noOfLines={1}>{props.text}</Text>}
        </Flex>
      </LinkBox>
    );
  }

  // Otherwise, just render the default Card attachment
  return <Card {...props} />;
}
