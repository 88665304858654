import { useRouter } from 'next/router';
import { BoxProps } from '@chakra-ui/react';

import { useUnreadCount } from '@arena-labs/chat';
import {
  ChatIcon,
  DataInsightsIcon,
  HomeIcon,
  PenIcon,
  ProductEducationPopover,
  useEducationPopoverViewed,
} from '@arena-labs/strive2-ui';
import { useUserProfile } from '@strive/api';
import { IfWearable } from '@strive/wearable';

import { useBiometricAlerts } from '../biometrics/use-biometric-alerts';
import { useStriveChatContext } from '../chat/chat-provider';
import { useCurrentIncentive } from '../incentives/use-current-incentive';
import { useDaysOnStrive } from '../profile/use-days-on-strive';
import { useFeatureUpdateIndicator } from '../version-update/feature-update-indicator';
import { FooterLink } from './footer-link';
import { FooterNav } from './footer-nav';
import { FooterNotification } from './footer-notification';

export type AppFooterProps = {
  sticky?: boolean;
  tabs?: React.ReactNode;
} & BoxProps;

export function AppFooter({ sticky, tabs, ...props }: AppFooterProps) {
  const route = useRouter();
  const { data: daysOnStrive } = useDaysOnStrive();
  const { data: user } = useUserProfile();
  const { channel } = useStriveChatContext();
  const unreadCount = useUnreadCount(channel);
  const biometricAlerts = useBiometricAlerts();
  const { data: incentive } = useCurrentIncentive();

  const enableChat = Boolean(user?.profile.team_coach_stream_username);

  const aciPopoverViewed = useEducationPopoverViewed('ACI_Start')[0];
  const sessionTrackerPopoverViewed =
    useEducationPopoverViewed('Daily_Tracker')[0];
  const competitionPopoverViewed =
    useEducationPopoverViewed('Competition_Hub')[0];

  const newIndicator = {
    Home: useFeatureUpdateIndicator('Home'),
    Practice: useFeatureUpdateIndicator('Practice'),
    Chat: useFeatureUpdateIndicator('Chat'),
    Videos: useFeatureUpdateIndicator('Videos'),
    Data: useFeatureUpdateIndicator('Data'),
  };

  return (
    <FooterNav tabs={tabs} sticky={sticky} {...props}>
      <FooterLink
        href="/"
        label="Home"
        icon={HomeIcon}
        isCurrent={
          route.pathname === '/' ||
          route.asPath.startsWith('/coaching/programs') ||
          route.asPath.startsWith('/content/videos/')
        }
      >
        <FooterNotification id="Home" isNew={newIndicator.Home} />
      </FooterLink>
      <ProductEducationPopover
        id={'Practice_Tab'}
        placement={'top'}
        shouldRender={
          aciPopoverViewed &&
          sessionTrackerPopoverViewed &&
          (incentive?.type !== 'competition' || competitionPopoverViewed) &&
          route.pathname === '/' &&
          Boolean(daysOnStrive && daysOnStrive >= 2)
        }
        title="Practices Tab"
        body={`Each practice you learn will be unlocked here. Log daily to uncover which practices have the biggest impact on pursuing your goal.`}
      >
        <FooterLink
          href="/practices"
          label="Practice"
          icon={PenIcon}
          isCurrent={route.asPath.startsWith('/practices')}
        >
          <FooterNotification id="Practice" isNew={newIndicator.Practice} />
        </FooterLink>
      </ProductEducationPopover>
      {enableChat && (
        <FooterLink
          href="/chat"
          label="Chat"
          icon={ChatIcon}
          isCurrent={route.asPath.startsWith('/chat')}
        >
          <FooterNotification
            id="chat"
            count={unreadCount}
            isNew={newIndicator.Chat}
          />
        </FooterLink>
      )}
      <IfWearable>
        <FooterLink
          href="/data"
          label="Progress"
          icon={DataInsightsIcon}
          isCurrent={route.asPath.startsWith('/data')}
        >
          <FooterNotification
            id="biometrics"
            count={biometricAlerts}
            isNew={newIndicator.Data}
          />
        </FooterLink>
      </IfWearable>
    </FooterNav>
  );
}
