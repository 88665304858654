import Link from 'next/link';
import { Stack, Text } from '@chakra-ui/react';

import { Icon, IconProps } from '@arena-labs/strive2-ui';

type FooterLinkProps = {
  href: string;
  icon: IconProps['as'];
  label: string;
  isCurrent: boolean;
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLElement>;
};
export function FooterLink({
  href,
  icon,
  label,
  isCurrent,
  children,
  onClick,
}: FooterLinkProps) {
  return (
    <Link href={href} passHref legacyBehavior>
      <Stack
        position={'relative'}
        as="a"
        onClick={onClick}
        aria-current={String(isCurrent) as 'true' | 'false'}
        direction={{ base: 'column', sm: 'row' }}
        px="3"
        py="2"
        bg={isCurrent ? 'strive.appFooter.activeBg' : undefined}
        color={isCurrent ? 'strive.appFooter.activeFg' : 'strive.appFooter.fg'}
        alignItems="center"
        justifyContent="center"
      >
        <Icon as={icon} size={4} />

        <Text
          lineHeight="1"
          fontSize="xs"
          textAlign="center"
          color={
            isCurrent ? 'strive.appFooter.activeFg' : 'strive.appFooter.fg'
          }
        >
          {label}
        </Text>
        {children}
      </Stack>
    </Link>
  );
}
