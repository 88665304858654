import { Channel, ChannelResponse, UserResponse } from 'stream-chat';

import { hasKey, isObjectWithKey } from '@arena-labs/shared-models';

import { StriveChatGenerics, StriveChatUserResponse } from './types';

export function isStreamUser(data: unknown): data is UserResponse {
  return (
    isObjectWithKey(data, 'name') &&
    'id' in data &&
    'role' in data &&
    'last_active' in data
  );
}

export function isStreamChannel(data: unknown): data is Channel {
  return isObjectWithKey(data, 'cid') && data.cid != null;
}

export function isChannelResponse(value: Channel): value is Omit<
  Channel<StriveChatGenerics>,
  'data'
> & {
  data: ChannelResponse<StriveChatGenerics>;
} {
  if (value.data && hasKey(value.data, 'cid') && value.data.cid) {
    return true;
  }
  return false;
}

export function isStriveChatUser(
  value: UserResponse,
): value is StriveChatUserResponse {
  if (value && hasKey(value, 'striveUser')) {
    return true;
  }
  return false;
}

export function isCoachChatUser(
  value: UserResponse,
): value is StriveChatUserResponse {
  if (value && hasKey(value, 'type') && Array.isArray(value.type)) {
    return (
      value.type?.includes('admin_coach') ||
      value.type?.includes('coach') ||
      value.type?.includes('launchpad_guide')
    );
  }
  return false;
}
