import { Message } from 'stream-chat';
import {
  MessageInput,
  MessageInputProps,
  MessageToSend,
  useChannelActionContext,
} from 'stream-chat-react';
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';

export type ChatMessageInputProps = MessageInputProps & {
  onMessageSent: (
    message: MessageToSend,
    channelCid: string,
    customMessageData?: Partial<Message<DefaultStreamChatGenerics>>,
  ) => void;
};

export function ChatMessageInput({
  onMessageSent,
  overrideSubmitHandler,
  ...props
}: ChatMessageInputProps) {
  const { sendMessage } = useChannelActionContext();

  return (
    <MessageInput
      overrideSubmitHandler={(
        message,
        cid,
        customMessageData?: Partial<Message<DefaultStreamChatGenerics>>,
      ) => {
        return sendMessage(message, customMessageData).then(() => {
          onMessageSent(message, cid, customMessageData);
        });
      }}
      {...props}
    />
  );
}
