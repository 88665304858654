import { useEffect, useState } from 'react';
import { Channel, Event } from 'stream-chat';

export function useUnreadCount(channel?: Channel | null) {
  const [unreadCount, setUnreadCount] = useState(0);
  useEffect(() => {
    if (!channel) {
      return;
    }
    const handleEvent = (event: Event) => {
      if (
        event?.type === 'notification.mark_read' &&
        event.cid !== channel.cid
      ) {
        setUnreadCount(0);
      } else {
        setUnreadCount(channel.countUnread());
      }
    };

    const client = channel.getClient();
    // Global events
    client.on('notification.mark_read', handleEvent);
    client.on('notification.message_new', handleEvent);
    client.on('connection.changed', handleEvent);
    client.on('connection.recovered', handleEvent);

    // Channel events
    channel.on('message.new', handleEvent);
    channel.on('message.updated', handleEvent);
    channel.on('message.deleted', handleEvent);

    // See https://arenalabs.atlassian.net/browse/AS-538
    setUnreadCount(channel.countUnread());

    return () => {
      client.off('notification.mark_read', handleEvent);
      client.off('connection.changed', handleEvent);
      client.off('connection.recovered', handleEvent);

      channel.off('message.new', handleEvent);
      channel.off('message.updated', handleEvent);
      channel.off('message.deleted', handleEvent);
    };
  }, [channel]);

  return unreadCount;
}
