import React, { useContext, useEffect, useState } from 'react';
import { StreamChat } from 'stream-chat';

import { StriveChatGenerics } from '../types';

export const ChatContext = React.createContext<StreamChat<StriveChatGenerics>>(
  new StreamChat(''),
);
export const useStriveChatContext = () => useContext(ChatContext);

type ChannelType = 'messaging' | 'launchpad';

export const ChatTypeContext = React.createContext<ChannelType>('messaging');
export const useChatType = () => useContext(ChatTypeContext);

export type ChatProviderProps = {
  apiKey: string;
  userId: string;
  userName: string;
  userToken: string;
  children:
    | React.ReactNode
    | ((client: StreamChat<StriveChatGenerics>) => React.ReactNode);
  channelType: ChannelType | null;
};

export function ChatProvider(props: ChatProviderProps) {
  const [client, setClient] = useState<StreamChat<StriveChatGenerics>>(
    () => new StreamChat(''),
  );

  const { apiKey, userId, userName, userToken } = props;
  useEffect(() => {
    const newClient = new StreamChat<StriveChatGenerics>(apiKey, {
      timeout: 10_000,
    });

    const handleConnectionChange = ({ online = false }) => {
      if (!online)
        return console.log('💬 %cChat:', 'font-weight:bold', 'connection lost');
      setClient(newClient);
      newClient.recoverState();
    };

    newClient.on('connection.changed', handleConnectionChange);

    newClient.connectUser({ id: userId, name: userName }, userToken);

    return () => {
      newClient.off('connection.changed', handleConnectionChange);
      newClient
        .disconnectUser()
        .then(() =>
          console.log('💬 %cChat:', 'font-weight:bold', 'connection closed'),
        );
    };
  }, [apiKey, userId, userName, userToken]);

  return (
    <ChatContext.Provider value={client}>
      <ChatTypeContext.Provider value={props.channelType ?? 'messaging'}>
        {typeof props.children === 'function'
          ? props.children(client)
          : props.children}
      </ChatTypeContext.Provider>
    </ChatContext.Provider>
  );
}
