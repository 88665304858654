import {
  Menu,
  MenuItem,
  Popover,
  PopoverAnchor,
  PopoverContent,
  useDisclosure,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { ImageComponent, ImageProps } from 'stream-chat-react';

import { ZStack } from '@arena-labs/strive2-ui';
import { assert, imageToBlob, saveAs } from '@strive/utils';

export function ImageAttachment(props: ImageProps) {
  const saveImage = useMutation({
    mutationFn: () => {
      assert(props.image_url, 'Image URL is null');

      const url = new URL(props.image_url);
      const pathname = url.pathname;
      const filename = pathname.replace(/^.*[\\/]/, '');

      return saveAs({
        filename: filename,
        data: imageToBlob({ url: props.image_url }),
        type: 'image/jpeg',
      });
    },
  });

  const disclosure = useDisclosure();
  return (
    <ZStack onContextMenu={disclosure.onToggle} placeItems="center">
      <ImageComponent {...props} />

      <Popover
        returnFocusOnClose={false}
        isOpen={disclosure.isOpen}
        onClose={disclosure.onClose}
        closeOnBlur={true}
        placement="bottom"
      >
        <PopoverAnchor>
          <div />
        </PopoverAnchor>
        <PopoverContent maxW="75%" marginInline="auto">
          <Menu>
            <MenuItem
              onClick={(e) => {
                e.preventDefault();
                saveImage.mutate();
              }}
            >
              {saveImage.isLoading ? 'Saving...' : 'Save Image'}
            </MenuItem>
          </Menu>
        </PopoverContent>
      </Popover>
    </ZStack>
  );
}
