import { useEffect } from 'react';
import { useCallbackRef } from '@chakra-ui/react';
import semver from 'semver';
import { z } from 'zod';

import { useDataStoreQuery } from '@strive/utils';

import { useAppInfo } from '../../lib/use-app-info';
import { previousVersionStore } from './use-installed-version-tracker';

const updates = {
  Home: null,
  Practice: null,
  Chat: null,
  Videos: null,
  Data: '3.5.5-0',
  Menu: null,
  Launchpad: null,
  OnboardGuide: null,
} as const;
type UpdateSection = keyof typeof updates;

function useUpdateSectionPreference(section: UpdateSection) {
  return useDataStoreQuery({
    key: `LastVisitedVersion.${section}`,
    schema: z.string(),
  });
}

export function useFeatureUpdateIndicator(section: UpdateSection) {
  const [lastVisitedVersion] = useUpdateSectionPreference(section);
  const appInfo = useAppInfo();
  const lastUpdate = updates[section];
  const [previousVersion] = useDataStoreQuery(previousVersionStore);

  // If any of the crucial data is not available, we can't determine
  // if the section is new or not, so we default to 'false'
  if (!appInfo?.version || !lastUpdate || !lastVisitedVersion.isSuccess) {
    return false;
  }

  // If this is a fresh install of the app, don't show new indicator
  else if (!previousVersion.data) {
    return false;
  }

  // If we don't have a record of the last visited version,
  // it means the user hasn't visited this section before,
  // hence we consider the section as new and return 'true'
  else if (!lastVisitedVersion.data) {
    return true;
  }

  // Otherwise, we compare the app's current version with the last update's
  // version and the last visited version.
  // This returns true if there's been an update since the user last visited.
  else {
    const lastVisited = lastVisitedVersion.data;
    return (
      semver.gte(appInfo.version, lastUpdate) &&
      semver.lt(lastVisited, lastUpdate)
    );
  }
}

export function useResetUpdateIndicator(section: UpdateSection) {
  const appInfo = useAppInfo();
  const [, setLastVisitedVersion] = useUpdateSectionPreference(section);
  const resetLastVisitedVersion = useCallbackRef((currentVersion: string) => {
    setLastVisitedVersion.mutateAsync(currentVersion);
  });
  useEffect(() => {
    if (appInfo?.version) {
      resetLastVisitedVersion(appInfo?.version);
    }
  }, [appInfo?.version, resetLastVisitedVersion]);
}
